<template>
  <div>
    <v-row>
      <v-col cols="12">
        <BillPayableReportByMonth @input="handleMonthInput($event)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BillPayableReportByDay ref="BillPayableReportByDay"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BillPayableReportByMonth from "@/components/bill-payable/sections/BillPayableReportByMonth";
import BillPayableReportByDay from "@/components/bill-payable/sections/BillPayableReportByDay";
import Vue from "vue";
import { isValid, parseISO, parse, lastDayOfMonth } from 'date-fns'

export default {
  components: { BillPayableReportByMonth, BillPayableReportByDay },

  data: () => ({}),

  mounted() {},

  methods: {
    handleMonthInput(val) {
      
      let date = parseISO(val.due_date_month)

      console.log(lastDayOfMonth(date))
      // console.log(val)
      let filter = {
        due_date_start : date,
        due_date_end : lastDayOfMonth(date)
      }
      this.$refs.BillPayableReportByDay.setFilters(filter)
    }
  },
};
</script>

<style>
</style>