<template>
  <v-card>
    <v-card-title>
      Contas A Pagar (Dia)
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <apexchart
        width="100%"
        height="300px"
        type="line"
        :options="options"
        :series="series"
      ></apexchart>
      <!-- {{ params }} -->
    </v-card-text>
  </v-card>
</template>

<script>

import Vue from "vue";

export default {
  props : {
    //filter : {}
  },

  data: () => ({
    response: {
      billPayables: [],
    },

    params: {
      // type : [],
      due_date_start : null,
      due_date_end : null,
      metrics: "value_sum",
      dimensions: "due_date_day",
      order: [],
    },
  }),

  computed: {
    categories() {
      return this.response.billPayables.map((val) => val.due_date_day);
    },

    values() {
      return this.response.billPayables.map((val) => val.value_sum);
    },

    options() {
      return {
        chart: {
          id: "vuechart-example",
          events: {
            dataPointSelection: function (event, chartContext, config) {
              // console.log(config.dataPointIndex)
              this.$emit(
                "input",
                this.response.billPayables[config.dataPointIndex]
              );
            },
          },
        },
        colors: ['#3C3ED2'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          // offsetY: -20,
          // style: {
          //   fontSize: "12px",
          //   colors: ["#212121"],
          // },
          formatter: (val) => Vue.$format.decimal(val),
        },
        xaxis: {
          categories: this.categories,
          labels: {
            formatter: (val) => Vue.$format.dateBr(val, "dd/MM"),
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => Vue.$format.decimal(val),
          },
        },
      };
    },

    series() {
      let series = [
        {
          name: "Valor",
          data: this.values,
        },
      ];

      return series;
    },
  },

  mounted() {
    this.select();
  },

  methods: {
    async select() {
      await this.$http
        .index("bill-payable/bill-payable-report", this.params)
        .then((response) => {
          this.response = response;
        });
    },

    setFilters(filters) {
      this.params = {...this.params, ...filters}
      this.select()
    }
  },
};
</script>

<style>
</style>