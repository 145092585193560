<template>
  <v-dialog v-model="dialog" max-width="600px" eager origin="center">
    <v-card>
      <v-card-title>Filtrar</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <company-select
              chips
              multiple
              v-model="editFilters.company_id"
              :item-value="(val) => val.id"
              :itemText="(val) => val.short_name"
              solo
              flat
              background-color="grey lighten-4"
              :dense="false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details="auto"
              type="date"
              solo
              flat
              background-color="grey lighten-4"
              v-model="editFilters.due_date_start"
              label="Vencimento Inicial"
            />
          </v-col>
          <v-col>
            <v-text-field
              hide-details="auto"
              type="date"
              solo
              flat
              background-color="grey lighten-4"
              v-model="editFilters.due_date_end"
              label="Vencimento Final"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AccountPlanSelect
              v-model="editFilters.account_plan_id"
              :item-value="(val) => val.id"
              :dense="false"
              solo
              flat
              background-color="grey lighten-4"
              multiple
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip-group
              v-model="editFilters.status"
              multiple
              mandatory
            >
              <v-chip filter outlined value="foreseen"> Previsão </v-chip>
              <v-chip filter outlined value="to_pay"> A Pagar </v-chip>
              <v-chip filter outlined value="paid"> Pago </v-chip>
              <v-chip filter outlined value="canceled"> Cancelado </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Voltar</v-btn
        >
        <v-btn
          class="text-capitalize"
          color="primary"
          @click="handleFilter()"
          >Filtrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AccountPlanSelect from "@/components/account-plan/ui/AccountPlanSelect.vue";

export default {
  components: { CompanySelect, AccountPlanSelect },
  props: {
    value: {},
    persistId : {},
    persist : {
      default : false
    }
  },

  data: () => ({
    dialog: false,
    
    filters: {},

    editFilters: {},
  }),

  

  created() {
    this.filters = JSON.parse(JSON.stringify(this.value));
    
    if (this.persist) {
      this.getStoredFilter()
    }
  },

  computed : {
    storageKey() {
      return this.user + '@' + this.persistId
    },

    user() {
      return this.$store.state.auth.userData.user.username
    }
  },

  methods: {
    getStoredFilter() {
      let filters = localStorage.getItem(this.storageKey)

      if (filters) {
        this.filters = JSON.parse(filters)
        
        this.$emit('input', this.filters)
      }

    },

    storeFilter() {
      localStorage.setItem(this.storageKey, JSON.stringify(this.filters))
    },

    open() {
      this.editFilters = JSON.parse(JSON.stringify(this.filters));
      this.dialog = true;
    },

    handleFilter() {
      this.filters = JSON.parse(JSON.stringify(this.editFilters));
      this.$emit('input', this.filters)
      
      if (this.persist) {
        this.storeFilter()
      }

      this.dialog = false
    }
  },
};
</script>

<style>
</style>