<template>
  <v-card>
    <v-card-title>
      Contas A Pagar (Mês)
      <v-spacer></v-spacer>
      <v-btn
        class="text-capitalize"
        color="primary"
        @click="openFilters()"
        >Filtros
      </v-btn>
      <BillPayableFilterDialog
        ref="BillPayableFilterDialog"
        v-model="filters"
        persist-id="bill-payable-by-month@bill-payable-report"
        :persist="true"
      />
    </v-card-title>
    <v-card-text>
      <apexchart
        width="100%"
        height="300px"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import BillPayableFilterDialog from "@/components/bill-payable/sections/BillPayableFilterDialog";
import Vue from "vue";

export default {
  name: "BillPayableReportByMonth",

  components: { BillPayableFilterDialog },

  data() {
    var self = this;
    return {
      response: {
        billPayables: [],
      },

      options: {
        chart: {
          id: "vuechart-example",
          events: {
            dataPointSelection: function (event, chartContext, config) {
              self.handleGraphInput(config.dataPointIndex);
            },
          },
        },
        colors: ['#3C3ED2'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#212121"],
          },
          formatter: (val) => Vue.$format.numeral(val),
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: (val) => Vue.$format.dateBr(val, "MM/yyyy"),
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => Vue.$format.decimal(val),
          },
        },
      },

      series: [],

      filters: {
        company_id: [],
        account_plan_id: [],
        text: null,
        page: 1,
        due_date_start: null,
        due_date_end: "2021-12-31",
        status: ["to_pay"],
        orderBy: null,
        orderDirection: null,
      },

      params: {
        metrics: "value_sum",
        dimensions: "due_date_month",
      },
    };
  },

  computed: {
    self() {
      return this;
    },
  },

  watch: {
    filters : {
      deep : true,
      handler() {
        this.select()
      }
    }
  },

  mounted() {
    this.select();
  },

  methods: {
    getParams() {
      return {...this.params, ...this.filters}
    },

    async select() {
      await this.$http
        .index("bill-payable/bill-payable-report", this.getParams())
        .then((response) => {
          this.response = response;
          this.setSeries();
          this.setOptions();
        });
    },

    handleGraphInput(input) {
      this.$emit("input", this.response.billPayables[input]);
    },

    setOptions() {
      let options = this.options;

      options.xaxis.categories = this.response.billPayables.map(
        (val) => val.due_date_month
      );

      this.options = JSON.parse(JSON.stringify(options));
    },

    setSeries() {
      this.series = [
        {
          name: "Valor",
          data: this.response.billPayables.map((val) => val.value_sum),
        },
      ];
    },

    openFilters() {
      this.$refs.BillPayableFilterDialog.open();
    },
  },
};
</script>

<style>
</style>